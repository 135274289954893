import styled from "styled-components";
import LoadingAnimation from "../../../../components/loadingAnimation";

const LoadingView = ({ loadingText = "loading" }) => {
  return (
    <LoadingViewWrapper>
      <LoadingAnimation />
      <span className="subheading">{loadingText}</span>
    </LoadingViewWrapper>
  );
};

export default LoadingView;

const LoadingViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .subheading {
    font-size: 14px;
    color: #91a1ac;
    font-weight: 500;
  }
`;
