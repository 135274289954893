import BaseChart from "../../baseChart";
import { useEChartsOption } from "../../../helpers/hooks/useEchartsOption";

export type DonutChartConfiguration = {
  config: {
    name: any;
    selector: string;
    value_formatter: {
      prefix: string;
      postfix: string;
    };
  };
  data: {
    series: { category: string; value: number; id: number | string }[];
  };
};
interface DonutChartProps {
  width: number;
  height: number;
  data: DonutChartConfiguration;
}

const DonutChart = ({ data, width, height }: DonutChartProps) => {
  const option = useEChartsOption(() => {
    let itemsArray = data.data.series
      .sort((a, b) => parseFloat(b.value) - parseFloat(a.value))
      .slice(0, 10);
    return {
      tooltip: {
        trigger: "item",
        formatter: (params) => `
            <div>
              <h5>${params.percent} %</h5>
              <b>${params.name}</b> - ${
          data.config.value_formatter.prefix
        } ${params.value.toLocaleString()} ${
          data.config.value_formatter.postfix
        }
            </div>
          `,
      },
      legend: {
        type: "scroll",
        orient: "vertical",
        left: "60%",
      },
      series: [
        {
          name: data.config.name,
          type: "pie",
          radius: ["50%", "85%"],
          right: "50%",
          width: "auto",
          height: "auto",
          avoidLabelOverlap: false,
          label: {
            show: true,
            formatter: data.config.name ? "{a}" : "",
            position: "center",
            fontWeight: "bold",
            fontSize: 15,
          },
          labelLine: {
            show: false,
          },
          data: itemsArray.map((d) => ({
            name: d.category,
            value: d.value,
            id: d.id,
          })),
        },
      ],
    };
  }, [data]);

  return (
    <BaseChart
      height={height}
      width={width}
      options={option}
      chartType="donut"
    />
  );
};

export default DonutChart;
