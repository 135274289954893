import { useEffect, useState } from "react";
import type { EChartsOption } from "echarts";
import BaseChart from "../../baseChart";
import { numFormatter } from "../../../helpers/utils";

export type BarChartConfiguration = {
  config: {
    name: any;
    selector: string;
    direction: "horizontal" | "vertical";
    sort_data: boolean;
    value_formatter: {
      prefix: string;
      postfix: string;
    };
  };
  data: {
    series: { id: number; category: string; value: number }[];
  };
};

interface IBarChartProps {
  width: number;
  height: number;
  data: BarChartConfiguration;
  handleDataPointClick: (e: any) => void;
}

const BarChart = (props: IBarChartProps) => {
  const [option, setOption] = useState<EChartsOption>({
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {},
    yAxis: {},
    series: [],
  });

  useEffect(() => {
    if (props.data) {
      setOption((o) => {
        let updatedOption = { ...o };
        let categories = props.data.data.series.map((d) => d.category);
        updatedOption.tooltip = {
          ...updatedOption.tooltip,
          valueFormatter: (value) =>
            `${props.data.config?.value_formatter.prefix} ${value.toLocaleString()} ${props.data.config?.value_formatter.postfix}`,
        };
        let categoryAxis: any = {
          type: "category",
          data: props.data.data.series.map((d) => d.category),
          axisPointer: {
            type: "shadow",
            show: true,
            label: {
              show: true
            }
          }
        };;
        let valueAxis: any = {
          type: "value",
          name: props.data.config?.name ?? "",
          axisPointer: {
            type: "line",
            show: true,
            triggerTooltip: false,
            label: {
              show: true,
            }
          },
          axisLabel: {
            formatter: function (value, index) {
              return `${props.data.config?.value_formatter.prefix}
              ${numFormatter(value)} ${
                props.data.config?.value_formatter.postfix
              }`;
            },
          },
        };
        if (props.data.config?.direction === "horizontal") {
          updatedOption.yAxis = { ...categoryAxis, inverse: true };
          updatedOption.xAxis = valueAxis;
        } else {
          updatedOption.yAxis = valueAxis;
          updatedOption.xAxis = categoryAxis;
        }
        updatedOption.series = [
          {
            type: "bar",
            itemStyle: {
              color: '#58585E'
            },
            data: props.data.data.series.map((d) => ({
              value: d.value,
              name: d.category,
            })),
          },
        ];
        updatedOption.grid = {
          left: props.width < 650 ? "20%" : "10%",
          right: "5%",
        }

        return updatedOption;
      });
    }
  }, [props.data, props.width]);

  const handleClickEvent = (e) => {
    props.handleDataPointClick(e);
  };

  return (
    <BaseChart
      height={props.height}
      width={props.width}
      options={option}
      chartType="bar"
      onClick={handleClickEvent}
    />
  );
};

export default BarChart;
