const currencyChecker = (currencyString) => {
  const currencyStringParts = currencyString.split(" ");
  if (typeof currencyString != "number" && currencyStringParts.length != 2) {
    return false;
  }
  return currencyStringParts;
}

const roundOff = (value, isRoundOffNeeded) => {
  return isRoundOffNeeded ? Math.round(value) : value
};

const numberFormatting = (number, isGrandTotal:boolean) => {
  let numberStringParts:any = currencyChecker(number);
  if (numberStringParts) {                                                     // currency string checking
    const value = numberStringParts[1] || 0
    const currencyName = numberStringParts[0];
    const currencyValue = roundOff(
      parseFloat(value),
      isGrandTotal
    );
    let formattedValue = currencyValue.toLocaleString();
    let formattedString = `${currencyName} ${formattedValue}`;
    return formattedString;
  } else { //non currency number strings
    if (number % 1 === 0) {                                                    //whole number
      return Math.floor(number).toLocaleString();
    } else {                                                                   // decimal number
      return roundOff(parseFloat(number), isGrandTotal).toLocaleString();
    }
  }
};

export {numberFormatting}
