import {
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  endOfMonth,
  format,
  parse,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
  startOfMonth,
  subDays,
  subHours,
  subMonths,
} from "date-fns";
const { toZonedTime, getTimezoneOffset } = require("date-fns-tz");
import { stringify } from "query-string";
import { MetaWidget } from "../types/widgets";
import { chartIcons } from "../components/baseChart/utils";

const convertDateTimeToTZEpoch = ({
  dateString,
  tzOffset = "+00:00",
  timeMode = "start",
  timeRange = [
    [0, 0],
    [23, 59],
  ],
}) => {
  // Parse the date string into year, month, and day
  const [year, month, day] = dateString.split("-").map(Number);

  // Convert the timezone offset to seconds and milliseconds
  const TZOffsetS = timezoneOffsetToSeconds(tzOffset);
  const TZOffsetMs = TZOffsetS * 1000;

  // Compute the UTC timestamp corresponding to the date at midnight in the target timezone
  let epochTimestamp = Date.UTC(year, month - 1, day) - TZOffsetMs;
  epochTimestamp /= 1000; // Convert to seconds

  // Determine the time based on timeMode and timeRange
  let timeArr = timeRange[0];
  if (timeMode !== "start") {
    timeArr = timeRange[1];
  }
  const [hr, min] = timeArr;
  let seconds = hr * 3600 + min * 60;
  if (timeMode !== "start") {
    seconds += 59;
  }

  // Add the seconds to the epoch timestamp
  return epochTimestamp + seconds;
};

/**
 * Converts a date range to their corresponding Unix epoch times.
 * @param dateRange Array of two strings representing the start and end dates in ISO format.
 * @param timezoneOffset time zone offset value (+05:30)
 * @returns An array containing the start and end dates as Unix epoch times.
 */
export const convertDateRangeToEpochTimestamp = (
  dateRange: [string, string], // [2024-06-01, 2024-06-05]
  timeRange: [[number, number], [number, number]] = [
    [0, 0],
    [23, 59],
  ], // [[0, 0], [23, 59]]
  timezoneOffset: string // "+04:00"
): [number, number] => {
  let startTime = timeRange[0];
  let endTime = timeRange[1];
  // Thu Jun 06 2024 00:00:00 GMT+0530 (India Standard Time)
  // let startDateInTZUTC = parseISO(dateRange[0]).getTime() / 1000;
  let startDateInTZUTC =
    addMinutes(
      addHours(parseISO(dateRange[0]), startTime[0]),
      startTime[1]
    ).getTime() / 1000;
  // Thu Jun 06 2024 23:59:59 GMT+0530 (India Standard Time)
  let endDateInTZUTC =
    addSeconds(
      addMinutes(addHours(parseISO(dateRange[1]), endTime[0]), endTime[1]),
      59
    ).getTime() / 1000;

  // local timezone offset in seconds
  let localTZOffset = new Date().getTimezoneOffset() * 60;
  let givenTZOffset = getTimezoneOffset(timezoneOffset) / 1000;
  let TZOffsetDelta = Math.abs(localTZOffset) - Math.abs(givenTZOffset);

  // If not native timezone, convert to that timezone's epoch
  if (timezoneOffset && TZOffsetDelta != 0) {
    startDateInTZUTC = convertDateTimeToTZEpoch({
      dateString: dateRange[0],
      timeRange: timeRange,
      timeMode: "start",
      tzOffset: timezoneOffset,
    });
    endDateInTZUTC = convertDateTimeToTZEpoch({
      dateString: dateRange[1],
      timeRange: timeRange,
      timeMode: "end",
      tzOffset: timezoneOffset,
    });
  }
  return [startDateInTZUTC, endDateInTZUTC];
};

export const configureUrlWithParams = (url, filters) => {
  let queryParams = "?";
  if (filters.locations.length > 0) {
    if (queryParams.length > 1) {
      queryParams += "&";
    }
    queryParams += stringify(
      { locations: filters.locations },
      { arrayFormat: "comma" }
    );
  }
  if (filters.dates[0] !== "") {
    if (queryParams.length > 1) {
      queryParams += "&";
    }
    let timeRange = filters.dates;
    if (filters.shouldUseEpocForTimeRange) {
      timeRange = convertDateRangeToEpochTimestamp(
        filters.dates,
        filters.timeRange,
        filters.timezoneOffset
      );
    }
    queryParams += stringify(
      {
        time_range: `${timeRange[0]}:${timeRange[1]}`,
      },
      { encode: false }
    );
  }
  if (filters.widgets.length > 0) {
    for (let i = 0; i < filters.widgets.length; i++) {
      if (queryParams.length > 1) {
        queryParams += "&";
      }
      let ids = filters.widgets[i].data.map((w) => w.id);
      queryParams += stringify(
        {
          [filters.widgets[i].key]: ids,
        },
        { arrayFormat: "comma" }
      );
    }
  }
  if (
    "additionalFilters" in filters &&
    filters.additionalFilters !== undefined &&
    filters.additionalFilters.length > 0
  ) {
    for (let i = 0; i < filters.additionalFilters.length; i++) {
      if (filters.additionalFilters[i].options) {
        if (queryParams.length > 1) {
          queryParams += "&";
        }
        let ids = filters.additionalFilters[i].options.map((d) => d.value);
        queryParams += stringify(
          {
            [filters.additionalFilters[i].filterKey]: ids,
          },
          { encode: false, arrayFormat: "comma" }
        );
      } else {
        // For dormant customers filters
        if (queryParams.length > 1) {
          queryParams += "&";
        }
        let ids = filters.additionalFilters[i].value;
        queryParams += stringify(
          {
            [filters.additionalFilters[i].key]: ids,
          },
          { encode: false }
        );
      }
    }
  }
  if ("ftsFilters" in filters) {
    let { ftsFilters } = filters;
    if (ftsFilters.length > 0) {
      for (let i = 0; i < ftsFilters.length; i++) {
        if (queryParams.length > 1) {
          queryParams += "&";
        }
        let filterQuery = ftsFilters[i].query;
        queryParams += stringify(
          {
            [`fts_${ftsFilters[i].key}`]: filterQuery,
          },
          { encode: false }
        );
      }
    }
  }
  return queryParams.length > 1 ? url + queryParams : url;
};

export function removeDuplicateArrayItems(arr, key = "id") {
  const uniqueIds = {};
  const result = [];

  for (const item of arr) {
    if (!uniqueIds[item[key]]) {
      uniqueIds[item[key]] = true;
      result.push(item);
    }
  }
  return result;
}

export const serializeFilterQueryParams = ({
  locationIds,
  dateRange,
  timeRange,
}: {
  locationIds?: number[] | undefined;
  dateRange: [string, string];
  timeRange: [[number, number], [number, number]];
}) => {
  let params = "?";
  if (dateRange) {
    let startRange = `${dateRange[0]}`;
    let endRange = `${dateRange[1]}`;
    if (timeRange) {
      let startTimeRange = `${padNumberWithZero(
        timeRange[0][0]
      )}${padNumberWithZero(timeRange[0][1])}`;
      let endTimeRange = `${padNumberWithZero(
        timeRange[1][0]
      )}${padNumberWithZero(timeRange[1][1])}`;
      startRange = `${dateRange[0]}.${startTimeRange}`;
      endRange = `${dateRange[1]}.${endTimeRange}`;
    }
    params += stringify(
      {
        from: startRange,
        to: endRange,
      },
      { encode: true, sort: false }
    );
  }
  if (params == "?") {
    params = "";
  }
  return params;
};

export const formatDateString = (dateString: string) => {
  let splitDate = dateString.split("-");
  if (splitDate.length > 1) {
    let date = new Date(
      parseInt(splitDate[0]),
      parseInt(splitDate[1]) - 1,
      parseInt(splitDate[2])
    );
    let formattedDate = format(date, "do MMM yyyy");
    return formattedDate;
  } else {
    return dateString;
  }
};

export function numFormatter(num) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
  } else if (num <= 999) {
    return num; // if value < 1000, nothing to do
  }
}

export const getEnvValueFor = (envKey: string | undefined) => {
  // For better maintainability, we let the caller of this method to pass ENV Keys as
  // they are defined in the .env file (ALL CAPS - SNAKE CASE)
  // So envKey is in the format "SAPAAD_CORE_BASE_URL"

  let reFormattedKey = "ENV_" + envKey;
  // so now reFormattedKey is ENV_SAPAAD_CORE_BASE_URL

  reFormattedKey = reFormattedKey
    .toLocaleLowerCase()
    .replace(/(\_\w)/g, function (k) {
      return k[1].toUpperCase();
    });
  // First lower case all, then camel case.
  // now reFormattedKey is envSapaadCoreBaseUrl

  let envValue = document.getElementById("root")?.dataset[reFormattedKey];
  if (envValue === undefined) {
    console.error("[ENV] CANNOT FIND ENV FOR " + envKey);
  }

  return envValue;
};

export const formatNumber = (value: string | number) =>
  typeof value === "string"
    ? parseInt(value.toLocaleString())
    : value.toLocaleString();

export const getAnalyticsMetaWidgets = (subWidgets: MetaWidget[]) => {
  let widgetsWithGroups = subWidgets.filter(
    (d) => Object.keys(d.group).length > 0
  );
  let uniqueKeysFromWidgetGroups = [
    ...new Set(widgetsWithGroups.map((d) => d.group).map((d) => d.key)),
  ];
  let groupWidgets: any[] = uniqueKeysFromWidgetGroups.map((key) => {
    let groupWidget = {
      title: "",
      url: "",
      render_type: "",
      type: "",
      sub_widgets: [],
    };
    let groupWithSameKey = widgetsWithGroups.filter((w) => w.group.key === key);
    groupWidget.title = groupWithSameKey[0].group.title;
    groupWidget.url = groupWithSameKey[0].group.key
      .toLowerCase()
      .replace(" ", "_");
    groupWidget.render_type = "group";
    groupWidget.type = groupWithSameKey[0].group.type;
    groupWidget.sub_widgets = groupWithSameKey?.map((w) => ({
      type: w.type,
      url: w.url,
    }));
    return groupWidget;
  });
  let widgets = subWidgets
    .filter((d) => !(Object.keys(d.group).length > 0))
    .map((d) => ({ ...d, render_type: "single" }));
  return [...widgets, ...groupWidgets];
};

export const createWidgetFilterConfig = (widgetData) => {
  let config = {
    enableFilters: false,
    enableInteractions: false,
    widgetFilterKey: "",
    filterTitle: "",
  };
  if (widgetData.widget_filter_config) {
    config.enableFilters = Boolean(widgetData.widget_filter_config.key);
    config.enableInteractions = Boolean(widgetData.widget_filter_config.key);
    config.filterTitle = widgetData.widget_filter_config.heading;
    config.widgetFilterKey = widgetData.widget_filter_config.key;
  }
  return config;
};

export const swrDefaultConfig = {
  refreshWhenOffline: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export const createDecimalNumberFormatter = (decimalPlaces) => {
  return (value) => {
    if (typeof value === "string") {
      return parseFloat(value).toFixed(decimalPlaces);
    }
    try {
      return value.toFixed(decimalPlaces);
    } catch (e) {
      return parseFloat(value).toFixed(decimalPlaces);
    }
  };
};

export const filterLocationsBySelectedDateRange = (
  companyLocations,
  selectedDateRange
) => {
  let parsedSelectedStartDate = parse(
    selectedDateRange[1],
    "yyyy-MM-dd",
    new Date()
  ).getTime();
  let parsedSelectedEndDate = parse(
    selectedDateRange[1],
    "yyyy-MM-dd",
    new Date()
  ).getTime();
  let validLocations = companyLocations.filter((d) => {
    if (!d.end_date) {
      return d;
    }
    let parsedLocationEndDate = parse(
      d.end_date,
      "yyyy-MM-dd",
      new Date()
    ).getTime();
    return (
      parsedSelectedEndDate <= parsedLocationEndDate ||
      parsedSelectedStartDate === parsedLocationEndDate
    );
  });
  return validLocations;
};

export const dataZoomSliderConfig = (limit) => {
  return [
    {
      type: "slider",
      height: 20,
      borderColor: "none",
      startValue: 0,
      endValue: limit ?? 40,
      moveHandleSize: -20,
      backgroundColor: "none",
      fillerColor: "#F1EDED",
      moveHandleStyle: {
        color: "grey",
        handleSize: 5,
      },
      dataBackground: {
        areaStyle: {
          color: "none",
          opacity: 0,
        },
        lineStyle: {
          color: "none",
          opacity: 0,
        },
      },
      z: 2,
      handleIcon: "circle",
      handleSize: 25,
      moveHandleIcon: chartIcons.scrollbarMoveHandle,
    },
    {
      type: "slider",
      height: 5,
      z: 0,
      startValue: 0,
      endValue: 40,
      moveHandleSize: -20,
      backgroundColor: "#F1EDED",
      moveHandleStyle: {
        color: "grey",
        handleSize: 5,
      },
      dataBackground: {
        areaStyle: {
          color: "none",
          opacity: 0,
        },
        lineStyle: {
          color: "none",
          opacity: 0,
        },
      },
      handleIcon: "",
      handleSize: 0,
      brushSelect: false,
    },
  ];
};

export const formatText = (text: string): string => {
  if (text.includes(" ")) {
    let words = text.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    let formattedText = words.join(" ");
    return formattedText;
  } else {
    return text;
  }
};

/**
 * Applies timezone offset to given date with the offset value
 * @param sourceDate - In epoch timestamp (seconds)
 * @param offset - Offset value ex. 'UTC+04:00'
 *
 * returns `formatted date`
 */
export const formatDateWithTimezoneOffset = (
  timestampS,
  timeZoneOffset,
  formatString = "yyyy-MM-dd HH:mm:ss"
) => {
  const [, sign, offsetHours, offsetMinutes] = timeZoneOffset.match(
    /([+-])(\d{2}):(\d{2})/
  );
  const totalOffsetInSeconds =
    (parseInt(offsetHours, 10) * 60 * 60 + parseInt(offsetMinutes, 10) * 60) *
    (sign === "-" ? -1 : 1);
  const timestampTZ = parseInt(timestampS) + totalOffsetInSeconds;
  const absoluteDate = new Date(timestampTZ * 1000);
  const weekDay = absoluteDate.getUTCDay();
  const day = absoluteDate.getUTCDate();
  const month = absoluteDate.getUTCMonth();
  const year = absoluteDate.getUTCFullYear();
  const hours = absoluteDate.getUTCHours();
  const minutes = absoluteDate.getUTCMinutes();
  const seconds = absoluteDate.getUTCSeconds();
  const milliseconds = absoluteDate.getUTCMilliseconds();

  const convertedDate = new Date(
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    milliseconds
  );
  return format(convertedDate, formatString);
};

export const generateRandomId = (length = 10) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export function timezoneOffsetToSeconds(offset) {
  const regex = /^([+-])(\d{2}):(\d{2})$/;
  const match = offset.match(regex);
  if (!match) {
    throw new Error("Invalid timezone format. Expected format: ±HH:MM");
  }
  const sign = offset[0] === "-" ? -1 : 1;
  const [hours, minutes] = offset.slice(1).split(":").map(Number);
  return sign * (hours * 3600 + minutes * 60);
}

export function createCompanyDateSelectionPresets({
  businessEndTimeInSecs = 0, // seconds from UTC 00:00
  timezoneOffset = "+00:00",
}) {
  // timezone offset in seconds UTC
  let offsetS = timezoneOffsetToSeconds(timezoneOffset);
  
  let businessEndSecs = businessEndTimeInSecs + offsetS;
  let businessEndHour = Math.floor(businessEndSecs / 3600);
  let businessEndMinute = Math.floor((businessEndSecs % 3600) / 60);
  if (businessEndMinute < 0) {
    businessEndMinute = 60 - Math.abs(businessEndMinute);
  }
  if (businessEndHour >= 24) {
    businessEndHour = businessEndHour - 24;
  }
  if (businessEndHour < 0) {
    businessEndHour = 24 - Math.abs(businessEndHour);
  }

  // Current date in company timezone
  let dateTimeinTZ =
    timezoneOffset !== "+00:00"
      ? toZonedTime(new Date(), timezoneOffset)
      : new Date();

  let todayBet = setHours(
    setMinutes(setSeconds(dateTimeinTZ, 0), businessEndMinute),
    businessEndHour
  );
  let yesterdayBet = setHours(
    setMinutes(subDays(dateTimeinTZ, 1), businessEndMinute),
    businessEndHour
  );
  let prevMonthFirstDayBet = setMinutes(
    setHours(startOfMonth(subMonths(dateTimeinTZ, 1)), businessEndHour),
    businessEndMinute
  );
  let prevMonthLastDayBet = setMinutes(
    setHours(endOfMonth(subMonths(dateTimeinTZ, 1)), businessEndHour),
    businessEndMinute
  );

  let offsetDayStart = 0
  let offsetDayEnd = 1

  let currentHours = dateTimeinTZ.getHours();
  let currentMinutes = dateTimeinTZ.getMinutes();

  // if local time less than business end time, subtract 1 day
  if (
    currentHours < businessEndHour ||
    (currentHours == businessEndHour && currentMinutes < businessEndMinute)
  ) {
    offsetDayStart = -1;
    offsetDayEnd = 0;
  }

  return {
    today: {
      title: "Today",
      date_range: [
        format(addDays(todayBet, offsetDayStart), "yyyy-MM-dd"),
        format(addDays(todayBet, offsetDayEnd), "yyyy-MM-dd"),
      ],
      time_range: [
        [businessEndHour, businessEndMinute],
        [businessEndHour, businessEndMinute],
      ],
    },
    yesterday: {
      title: "Yesterday",
      date_range: [
        format(addDays(yesterdayBet, offsetDayStart), "yyyy-MM-dd"),
        format(addDays(yesterdayBet, offsetDayEnd), "yyyy-MM-dd"),
      ],
      time_range: [
        [businessEndHour, businessEndMinute],
        [businessEndHour, businessEndMinute],
      ],
    },
    lastMonth: {
      title: "Last Month",
      date_range: [
        format(addDays(prevMonthFirstDayBet, offsetDayStart), "yyyy-MM-dd"),
        format(addDays(prevMonthLastDayBet, offsetDayEnd), "yyyy-MM-dd"),
      ],
      time_range: [
        [businessEndHour, businessEndMinute],
        [businessEndHour, businessEndMinute],
      ],
    },
  };
}

export const padNumberWithZero = (value: number) => {
  return value.toString().padStart(2, "0");
};

/**
 * Validates a time range string in the format "HHMM".
 * @param timeRange - A string representing time in 24-hour format (e.g., "0000" for midnight, "2359" for 11:59 PM).
 * @param defaultTimeRange - The default time range to return if the input is invalid.
 * @returns The validated time range or the default if invalid.
 */
export const validateUrlTimeValue = ({
  timeRange = "0000",
  defaultTimeRange = "0000",
}) => {
  const hours = parseInt(timeRange.slice(0, 2));
  const minutes = parseInt(timeRange.slice(2, 4));
  if (
    isNaN(hours) ||
    isNaN(minutes) ||
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59
  ) {
    return defaultTimeRange; // Return default value for invalid input
  }
  let hourString = hours.toString().padStart(2, "0");
  let minuteString = minutes.toString().padStart(2, "0");
  return `${hourString}${minuteString}`;
};

export const convertUrlTimeValueToHrMin = (timeRange) => {
  const hour = parseInt(timeRange.slice(0, 2));
  const minute = parseInt(timeRange.slice(2, 4));
  return [hour, minute];
};

/**
 * Validates and processes URL date-time filters.
 * 
 * @param {Object} params - The input parameters
 * @param {string[]} params.defaultDateRange - Default start and end dates
 * @param {number[][]} params.defaultTimeRange - Default start and end times as arrays of [hour, minute]
 * @param {string} [params.startRange] - Start range from URL in format "YYYY-MM-DD.HHMM"
 * @param {string} [params.endRange] - End range from URL in format "YYYY-MM-DD.HHMM"
 * @param {Function} callback - Function to call with processed date and time ranges
 * 
 * This function parses and validates date-time ranges from URL parameters.
 * It falls back to default values if URL parameters are missing or invalid.
 * The function handles both date and time components, ensuring they are in the correct format.
 * After processing, it calls the provided callback function with the validated ranges.
 */
export const validateUrlDateTimeFilters = (
  { defaultDateRange, defaultTimeRange, startRange, endRange },
  callback = ({
    startDateRange,
    endDateRange,
    startTimeRange,
    endTimeRange,
  }) => {}
) => {
  let [startDate, endDate] = defaultDateRange;
  let [startTime, endTime] = defaultTimeRange;
  let [startHour, startMinute] = startTime;
  let [endHour, endMinute] = endTime;
  let startDateRange = startRange?.split(".")[0] ?? startDate;
  let endDateRange = endRange?.split(".")[0] ?? endDate;
  let startTimeRange = startRange?.split(".")[1];
  let endTimeRange = endRange?.split(".")[1];
  let defaultStartTimeRange = `${padNumberWithZero(
    startHour
  )}${padNumberWithZero(startMinute)}`;
  let defaultEndTimeRange = `${padNumberWithZero(endHour)}${padNumberWithZero(
    endMinute
  )}`;
  if (startTimeRange) {
    startTimeRange = convertUrlTimeValueToHrMin(
      validateUrlTimeValue({
        timeRange: startTimeRange,
        defaultTimeRange: defaultStartTimeRange,
      })
    );
  } else {
    startTimeRange = startTime;
  }
  if (endTimeRange) {
    endTimeRange = convertUrlTimeValueToHrMin(
      validateUrlTimeValue({
        timeRange: endTimeRange,
        defaultTimeRange: defaultEndTimeRange,
      })
    );
  } else {
    endTimeRange = endTime;
  }
  callback({ startDateRange, endDateRange, startTimeRange, endTimeRange });
};
