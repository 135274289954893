import type { EChartsOption } from "echarts";
import { useMemo } from "react";
import BaseChart from "../../baseChart";
import { numFormatter } from "../../../helpers/utils";
import { formatDateWithTimezoneOffset } from "./utils";
import { chartIcons } from "../../baseChart/utils";

type WaterfallChartConfigurations = {
  config: {
    visual: {
      movement_types: {
        accessor: string;
        issued: {
          accessor: string;
          value: string;
          display_name: string;
          color: string;
        };
        created: {
          accessor: string;
          value: string;
          display_name: string;
          color: string;
        };
        received: {
          accessor: string;
          value: string;
          display_name: string;
          color: string;
        };
      };
      value_props: {
        name: string;
        accessor: string;
        value_formatter?: string;
        color: string;
      };
      opening_balance: {
        accessor: string;
        display_name: string;
        color: string;
      };
      closing_balance: {
        accessor: string;
        display_name: string;
        color: string;
      };
      category_config: {
        headers: {
          accessor: string;
          name: string;
          type: string;
        }[];
      };
    };
  };
  data: any[];
};

interface IWaterfallChartProps {
  data: WaterfallChartConfigurations;
  height: number;
  width: number;
}

const WaterfallChart = ({ data, height, width }: IWaterfallChartProps) => {
  const option = useMemo<EChartsOption>(() => {
    let opt: any = {
      series: [],
    };
    if (data.data.length) {
      let timezoneOffset = "";
      if ("offset" in data.data[0]) {
        timezoneOffset = data.data[0].offset;
      }

      let valueCategories = data.data.map((d) => {
        let dat = {};
        data.config.visual.category_config.headers.forEach((h) => {
          if (h.type.toLowerCase() == "date") {
            dat[h.accessor] = formatDateWithTimezoneOffset(
              d[h.accessor],
              timezoneOffset
            );
          } else {
            dat[h.accessor] = d[h.accessor];
          }
        });
        return dat;
      });

      let values = data.config.visual.value_props.accessor
        ? data.data.map((d) => d[data.config.visual.value_props.accessor])
        : [];
      if (values.length > 0) {
        values = ["-", ...values, "-"];
      }
      let closingBalanceCategory = {};
      let openingBalanceCategory = {};
      data.config.visual.category_config.headers.forEach((h) => {
        if (h.type === "text") {
          openingBalanceCategory[h.accessor] =
            data.config.visual.opening_balance.display_name;
          closingBalanceCategory[h.accessor] =
            data.config.visual.closing_balance.display_name;
        } else {
          openingBalanceCategory[h.accessor] = "";
          closingBalanceCategory[h.accessor] = "";
        }
      });
      let finalCategories = [
        openingBalanceCategory,
        ...valueCategories,
        closingBalanceCategory,
      ];

      let movementTypes = {
        issued: {
          displayName: data.config.visual.movement_types.issued.display_name,
          color: data.config.visual.movement_types.issued.color,
          accessor: data.config.visual.movement_types.issued.accessor,
          value: data.config.visual.movement_types.issued.value,
        },
        received: {
          displayName: data.config.visual.movement_types.received.display_name,
          color: data.config.visual.movement_types.received.color,
          accessor: data.config.visual.movement_types.received.accessor,
          value: data.config.visual.movement_types.received.value,
        },
      };
      let openingBalance = {
        displayName: data.config.visual.opening_balance.display_name,
        value: parseFloat(
          data.data[0][data.config.visual.opening_balance.accessor]
        ),
        color: data.config.visual.opening_balance.color,
      };
      let movementTypesStatus = data.config.visual.movement_types.accessor;
      let closingBalance = {
        displayName: data.config.visual.closing_balance.display_name,
        value: data.data[data.data.length - 1].closing_balance,
        color: data.config.visual.closing_balance.color,
      };
      let categoryAxisName = data.config.visual.category_config.headers
        .map((d) => d.name)
        .join("\n");

      let startValue = openingBalance.value;
      let currentValue = openingBalance.value;
      let positiveValues: (string | number)[] = [];
      let negativeValues: (string | number)[] = [];
      let placeholderValues: (string | number)[] = [];
      data.data.forEach((d) => {
        placeholderValues.push(currentValue);
        if (d.status === "issued") {
          currentValue -= parseFloat(d[movementTypes.issued.accessor]);
          positiveValues.push("-");
          negativeValues.push(parseFloat(d[movementTypes.issued.accessor]));
        } else if (d.status === "received") {
          currentValue += parseFloat(d[movementTypes.received.accessor]);
          positiveValues.push(parseFloat(d[movementTypes.received.accessor]));
          negativeValues.push("-");
        } else {
          positiveValues.push("-");
          negativeValues.push("-");
        }
      });
      positiveValues = ["-", ...positiveValues, "-"];
      negativeValues = ["-", ...negativeValues, "-"];
      placeholderValues = ["-", ...placeholderValues, "-"];
      let startValsArr = [startValue, ...data.data.map((d) => "-"), "-"];
      let endValsArr = ["-", ...data.data.map((d) => "-"), currentValue];
      opt = {
        ...opt,
        legend: {
          show: true,
          data: [
            openingBalance.displayName,
            movementTypes.issued.displayName,
            movementTypes.received.displayName,
            closingBalance.displayName,
            data.config.visual.value_props.name,
          ],
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let seriesData: any = [];
            if (Array.isArray(params)) {
              let hasNoMovementType =
                params
                  .filter(
                    (d) =>
                      ![
                        "Placeholder",
                        openingBalance.displayName,
                        closingBalance.displayName,
                      ].includes(d.seriesName)
                  )
                  .filter((d) => d.value == "-").length === 2;
              if (hasNoMovementType) {
                seriesData = params.filter(
                  (d) => d.seriesName === "Placeholder"
                );
              } else {
                seriesData = params
                  ?.filter((d) => d.value !== "-")
                  ?.filter((d) => d.seriesName !== "Placeholder");
              }
            } else {
              seriesData = [params];
            }
            let htmlStart = "";
            let headingHtml = seriesData.map((d) => {
              let headingsString = d.name;
              let headingsArr = headingsString.split("|");
              let headingText = headingsArr
                .map((c) => `<span>${c}</span><br/>`)
                .join("");
              if (d.seriesName !== "Placeholder") {
                return headingText;
              } else {
                return `${headingText} ${d.value.toLocaleString()}`;
              }
            })[0];
            let seriesHtml = seriesData
              .map((d) => {
                if (d.seriesName !== "Placeholder") {
                  let res = `${d.marker} ${d.seriesName}`;
                  let valueSeriesName = data?.config?.visual?.value_props?.name;
                  if (d.seriesName === valueSeriesName) {
                    let valueFormatter =
                      data?.config?.visual?.value_props?.value_formatter ?? "";
                    return `${res} ${d.value.toLocaleString()} ${valueFormatter}`;
                  }
                  return `${res} ${d.value.toLocaleString()}`;
                }
                return "";
              })
              .join("<br/>");
            let htmlEnd = "";
            return `${htmlStart} ${headingHtml} ${seriesHtml} ${htmlEnd}`;
          },
        },
        xAxis: [
          {
            type: "category",
            position: "bottom",
            name: categoryAxisName,
            data: finalCategories.map((d) => {
              let values = Object.entries(d).map((f) => f[1]);
              let text = values.join("|");
              return text;
            }),
            axisPointer: {
              show: true,
              type: "shadow",
            },
            axisLabel: {
              hideOverlap: false,
              formatter: function (params) {
                let split = params.split("|");
                if (split.length > 0) {
                  return `${split[0]}\n${split[1]}`;
                }
                return `${params}`;
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
          {
            type: "value",
          },
        ],
        dataZoom: [
          {
            type: "slider",
            bottom: "2px",
            height: 20,
            borderColor: "none",
            startValue: 0,
            endValue: 40 - 1,
            moveHandleSize: -20,
            backgroundColor: "none",
            fillerColor: "#F1EDED",
            moveHandleStyle: {
              color: "grey",
              handleSize: 5,
            },
            dataBackground: {
              areaStyle: {
                color: "none",
                opacity: 0,
              },
              lineStyle: {
                color: "none",
                opacity: 0,
              },
            },
            z: 2,
            handleIcon: "circle",
            handleSize: 25,
            moveHandleIcon: chartIcons.scrollbarMoveHandle,
          },
          {
            type: "slider",
            bottom: "2px",
            height: 5,
            z: 0,
            startValue: 0,
            endValue: 40,
            moveHandleSize: -20,
            backgroundColor: "#F1EDED",
            moveHandleStyle: {
              color: "grey",
              handleSize: 5,
            },
            dataBackground: {
              areaStyle: {
                color: "none",
                opacity: 0,
              },
              lineStyle: {
                color: "none",
                opacity: 0,
              },
            },
            handleIcon: "",
            handleSize: 0,
            brushSelect: false,
          },
        ],
        series: [
          {
            name: openingBalance.displayName,
            color: openingBalance.color,
            yAxisIndex: 0,
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "top",
            },
            data: startValsArr,
          },
          {
            name: "Placeholder",
            type: "bar",
            yAxisIndex: 0,
            stack: "Total",
            silent: true,
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent",
              },
            },
            data: placeholderValues,
          },
          {
            name: movementTypes.received.displayName,
            color: movementTypes.received.color,
            yAxisIndex: 0,
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "top",
            },
            data: positiveValues,
          },
          {
            name: movementTypes.issued.displayName,
            color: movementTypes.issued.color,
            yAxisIndex: 0,
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "bottom",
            },
            data: negativeValues,
          },
          {
            name: closingBalance.displayName,
            color: closingBalance.color,
            yAxisIndex: 0,
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "top",
            },
            data: endValsArr,
          },
          {
            name: data.config.visual.value_props.name,
            yAxisIndex: 1,
            type: "line",
            color: data.config.visual.value_props.color,
            data: values,
          },
        ],
      };
    }
    return opt;
  }, [data]);

  return (
    <BaseChart chartType="bar" options={option} height={height} width={width} />
  );
};

export default WaterfallChart;
