import type { SWRResponse } from "swr";

export enum RESULT_TYPE {
  RESULT = "result",
  ERROR = "error",
  TIMEOUT = "timeout",
  MISSING_INPUT = "missing_input",
  PENDING = "pending",
  BLOCKED = "blocked",
}

export type ResponseType = {
  result_meta: {
    result_id?: string;
    result_type: RESULT_TYPE;
  };
  dashboard_data: any;
};
