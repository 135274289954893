import { removeDuplicateArrayItems } from "../../../../helpers/utils";
import { StackedBarChartType } from "../../../../types/widgets";

const getFilterOptionsfromData = (widgetData) => {
  let idAccessor = widgetData.config.filter.id_accessor;
  let nameAccessor = widgetData.config.filter.name_accessor;
  let arr = widgetData.data.map((d) => ({
    id: d[idAccessor],
    name: d[nameAccessor],
  }));
  return arr;
};

const transformDataToVisualFormat = (
  widgetData: StackedBarChartType,
  valueAccessor
) => {
  let config = {
    valueFormatter: "",
    showLegends: true,
    orientation: "vertical",
    showStackLabels: false,
    valueAxisName: "",
    categoryAxisName: "",
  };
  let data = {
    categories: [],
    series: [],
  };
  let selectedValueAccessor =
    widgetData.config.visual.value_configs[0].accessor;
  let selectedValueName = widgetData.config.visual.value_configs[0].name;
  if (valueAccessor) {
    selectedValueAccessor = valueAccessor.key;
    selectedValueName = valueAccessor.name;
  }
  let monetaryValueAccessors = widgetData.config.visual.value_configs
    .filter((d) => d.is_monetary_value_type)
    .map((d) => d.accessor);
  let categoryAccessor = widgetData.config.visual.category_config.accessor;
  let categoryIdAccessor = widgetData.config.visual.category_config.id_accessor;
  let stackByIdAccessor = widgetData.config.visual.stack_config.id_accessor;
  let stackByAccessor = widgetData.config.visual.stack_config.accessor;
  if (monetaryValueAccessors.includes(selectedValueAccessor)) {
    config.valueFormatter = widgetData.config.currency_formatter;
  }
  config.showLegends = widgetData?.config?.visual?.show_legends;
  config.showStackLabels =
    widgetData?.config?.visual?.show_stack_labels ?? false;
  config.orientation = widgetData?.config?.visual?.orientation ?? "vertical";
  config.valueAxisName = selectedValueName;
  config.categoryAxisName = widgetData.config.visual.stack_config.name;

  let categoryData = removeDuplicateArrayItems(
    widgetData.data,
    categoryIdAccessor
  ).map((d) => ({ id: d[categoryIdAccessor], name: d[categoryAccessor] }));

  let stacks = removeDuplicateArrayItems(
    widgetData.data,
    stackByIdAccessor
  ).map((d) => ({ id: d[stackByIdAccessor], name: d[stackByAccessor] }));

  let seriesData = stacks.map((d) => {
    let filteredByStackVals = widgetData.data.filter(
      (s) => s[stackByIdAccessor] === d.id
    );
    let data = categoryData.map((c) => {
      let filterStackValsInCat = filteredByStackVals.filter(
        (fs) => fs[categoryIdAccessor] === c.id
      );
      // if no stack value present in current category - needs revisit later
      if (filterStackValsInCat.length === 0) {
        return null;
      }
      // if there are multiple values, sum them
      let value = filterStackValsInCat.reduce(
        (t, r) => t + parseFloat(r[selectedValueAccessor]),
        0
      );
      if(value == 0 ) {
        return null;
      }
      return value;
    });
    return { id: d.id, name: d.name, data };
  });
  data.series = seriesData;
  data.categories = categoryData.map((d) => d.name);
  return { config, data };
};

const formatTooltip = ({
  seriesContent,
  valueFormatter,
  valueName,
  categoryName,
}) => {
  let data = seriesContent.filter((d) => d.data);
  let headerHtml = `<h4>${data.length ? `${data[0].axisValue}` : ""}</h4>`;
  let tableStart = `<table>
  <thead>
    <tr>
      <th style='padding: 5px'></th>
      <th style='padding: 5px'>${categoryName}</th>
      <th style='padding: 5px'> </th>
      <th style='padding: 5px'>${valueName}</th>
    </tr>
  </thead>
  <tbody>
  `;
  let tableContent = data
    .map(
      (u) => `
  <tr>
    <td style='padding: 5px'>${u.marker}</td>
    <td style='padding: 5px'>${u.seriesName}</td>
    <td style='padding: 5px'> </td>
    <td style='padding: 5px'><b>${u.value.toLocaleString()} ${valueFormatter}</b></td>
  </tr>
  `
    )
    .join("");
  let tableEnd = `</tbody></table>`;
  let contentHtml = data
    .map(
      (u) =>
        `<div>${u.marker} ${
          u.seriesName
        } <b>${u.value.toLocaleString()} ${valueFormatter}</b></div>`
    )
    .join("");
  return `${headerHtml}${tableStart}${tableContent}${tableEnd}`;
};

export { transformDataToVisualFormat, getFilterOptionsfromData, formatTooltip };
