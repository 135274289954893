import { useMemo } from "react";
import type { EChartsOption } from "echarts";
import BaseChart from "../../baseChart";
import { numFormatter } from "../../../helpers/utils";
import { chartIcons } from "../../baseChart/utils";
import { convertTimestampToTimeZone } from "./utils";

export type MultiSeriesComboChartConfiguration = {
  config: {
    mark_lines: {
      name: string;
      axis: "y-axis" | "x-axis";
      value: number | string;
      value_formatter: string;
      color?: string;
    }[];
  };
  data: {
    series: {
      name: string;
      type: string;
      value_formatter: string;
      data: {
        category: string;
        value: number;
      }[];
    }[];
  };
};

interface IMultiSeriesComboChartProps {
  width: number;
  height: number;
  data: MultiSeriesComboChartConfiguration;
  extraConfigs?: any;
  limitBars?: number;
}

const MultiSeriesComboChart = ({
  data,
  width,
  height,
  extraConfigs,
  limitBars = 40,
}: IMultiSeriesComboChartProps) => {
  const firstElementLength = data.data.series[0].data.length;

  const chartSeriesData = () => {
    let barSeriesData = data.data.series.filter((d) => d.type === "bar");
    let lineSeriesData = data.data.series.filter((d) => d.type === "line");
    let chartData = data.data.series.map((d) => ({
      type: d.type,
      name: d.name,
      markLine: {},
      yAxisIndex: barSeriesData.length > 0 ? (d.type === "bar" ? 0 : 1) : 0,
      tooltip: {
        show: true,
        valueFormatter: (value) =>
          `${value.toLocaleString()} ${d.value_formatter}`,
      },
      data: d.data.map((i) => ({
        name: i.category,
        value: i.value,
      })),
    }));
    chartData[0].markLine = data.config?.mark_lines
      ? {
          symbolSize: 0,
          data: data.config.mark_lines.map((d) => ({
            name: d.name,
            [d.axis === "y-axis" ? "yAxis" : "xAxis"]: d.value,
            lineStyle: {
              color: d.color ?? "red",
              width: 3,
            },
            label: {
              show: true,
              formatter: ({ name, value }) =>
                `${name}: ${value.toLocaleString()} ${d.value_formatter}`,
              position: "insideMiddle",
              backgroundColor: d.color ?? "red",
              color: "#ababab",
              padding: 3,
            },
          })),
        }
      : {};
    return chartData;
  };

  const option = useMemo<EChartsOption>(() => {
    let opt: EChartsOption = {
      tooltip: {},
      legend: {},
      xAxis: [],
      yAxis: [],
      series: [],
    };
    if (data) {
      let barSeriesData = data.data.series.filter((d) => d.type === "bar");
      let lineSeriesData = data.data.series.filter((d) => d.type === "line");
      let axesScales: any[] = [];
      if (barSeriesData.length > 0) {
        axesScales.push({
          type: "value",
          name: barSeriesData
            .map((d) => d.name)
            .reduce((a, b) => a + " / " + b),
          axisLabel: {
            formatter: (value) =>
              `${numFormatter(value)} ${barSeriesData[0].value_formatter}`,
          },
        });
      }
      if (lineSeriesData.length > 0) {
        axesScales.push({
          type: "value",
          name:
            barSeriesData.length > 0 && lineSeriesData.length < 3
              ? lineSeriesData
                  .map((d) => d.name)
                  .reduce((a, b) => a + " / " + b)
              : "",
          axisLabel: {
            formatter: (value) =>
              `${numFormatter(value)} ${lineSeriesData[0].value_formatter}`,
          },
        });
      }
      opt = {
        ...opt,
        legend: {
          top: "bottom",
          data: data.data.series.map((d) => d.name),
          type: "scroll",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            show: true,
            type: "shadow",
          },
        },
        xAxis: [
          {
            type: "category",
            data: data.data.series[0].data.map((d) => d.category),
          },
        ],
        yAxis: axesScales,
        dataZoom:
          firstElementLength > 1
            ? [
                {
                  type: "slider",
                  height: 20,
                  borderColor: "none",
                  startValue: 0,
                  endValue: limitBars - 1,
                  moveHandleSize: -20,
                  backgroundColor: "none",
                  fillerColor: "#F1EDED",
                  moveHandleStyle: {
                    color: "grey",
                    handleSize: 5,
                  },
                  dataBackground: {
                    areaStyle: {
                      color: "none",
                      opacity: 0,
                    },
                    lineStyle: {
                      color: "none",
                      opacity: 0,
                    },
                  },
                  z: 2,
                  handleIcon: "circle",
                  handleSize: 25,
                  moveHandleIcon: chartIcons.scrollbarMoveHandle,
                },
                {
                  type: "slider",
                  height: 5,
                  z: 0,
                  startValue: 0,
                  endValue: 40,
                  moveHandleSize: -20,
                  backgroundColor: "#F1EDED",
                  moveHandleStyle: {
                    color: "grey",
                    handleSize: 5,
                  },
                  dataBackground: {
                    areaStyle: {
                      color: "none",
                      opacity: 0,
                    },
                    lineStyle: {
                      color: "none",
                      opacity: 0,
                    },
                  },
                  handleIcon: "",
                  handleSize: 0,
                  brushSelect: false,
                },
              ]
            : [],
        series: chartSeriesData(),
      };
    }

    if (extraConfigs && "trendPeriod" in extraConfigs) {
      if (
        extraConfigs.trendPeriod &&
        extraConfigs.trendPeriod.includes("trends-hourly")
      ) {
        return {
          ...opt,
          xAxis: [
            {
              type: "category",
              data: data.data.series[0].data.map((d) => {
                if (d.offset) {
                  return convertTimestampToTimeZone(
                    d.category,
                    d.offset,
                    "dd/MM - hh:mm a"
                  );
                }
                return d.category;
              }),
            },
          ],
        };
      }
    }
    return opt;
  }, [data]);

  return (
    <BaseChart
      height={height}
      width={width}
      options={option}
      chartType="bar-line"
    />
  );
};

export default MultiSeriesComboChart;
