import { Popup } from "react-map-gl";
import styled from "styled-components";

export const MapLayerPopup = ({ layerHoverData }) => {
  return (
    <Popup
      latitude={layerHoverData.coordinates.lat}
      longitude={layerHoverData.coordinates.lng}
      closeButton={false}>
      {layerHoverData.content.map((layerContent, index) => {
        const {
          location_name,
          numeric_amount_name,
          numeric_amount_prefix,
          numeric_amount_value,
          numeric_amount_postfix,
          numeric_count_name,
          numeric_count_prefix,
          numeric_count_value,
          numeric_count_postfix,
        } = layerContent.properties;
        return (
          <PopupWrapper>
            <div>
              <h2 className="location-name">{location_name}</h2>
              <div className="numeric-data-content">
                {/* <span className="label">{numeric_amount_name}:</span> */}
                <span className="value-amount-prefix">
                  {numeric_amount_prefix}
                </span>
                <span className="value-amount">
                  {numeric_amount_value.toLocaleString()}
                </span>
                <span className="value-amount-postfix">
                  {numeric_amount_postfix}
                </span>
              </div>
              <div className="numeric-data-content">
                <span className="value-prefix">{numeric_count_prefix}</span>
                <span className="value-count">
                  {numeric_count_value.toLocaleString()}
                </span>
                <span className="value-postfix">{numeric_count_postfix}</span>
                <span className="label-count">
                  {numeric_count_name.toLowerCase()}
                </span>
              </div>
            </div>
          </PopupWrapper>
        );
      })}
    </Popup>
  );
};

const PopupWrapper = styled.div`
  margin-top: 10px;
  padding: 10px;
  .location-name {
    display: block;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 30px;
  }
  .numeric-data-content {
    .label-amount {
      font-size: 18px;
      font-weight: 400;
      padding: 2px;
    }
    .label-count {
      font-size: 13px;
      font-weight: 400;
      padding: 2px;
    }
    .value-amount {
      font-size: 17px;
      font-weight: 400;
      padding-right: 2px;
    }
    .value-count {
      font-size: 13px;
    }
    .value-amount-postfix {
      font-size: 17px;
    }
  }
`;
