import BaseChart from "../../baseChart";
import { useEChartsOption } from "../../../helpers/hooks/useEchartsOption";
import { chartIcons } from "../../baseChart/utils";

export type MultiSetBarChartConfiguration = {
  config: {
    selector: string;
    direction: "horizontal" | "vertical";
    value_formatter: {
      prefix: string;
      postfix: string;
    };
  };
  data: {
    series: {
      name: string;
      series_data: { id: number; category: string; value: number }[];
    }[];
  };
};

interface IMultiSetBarChartProps {
  width: number;
  height: number;
  data: MultiSetBarChartConfiguration;
}

const MultiSetBarChart = ({ width, height, data }: IMultiSetBarChartProps) => {
  const options = useEChartsOption(() => {
    if (data) {
      let vfPrefix = data.config?.value_formatter?.prefix ?? "";
      let vfPostFix = data.config?.value_formatter?.postfix ?? "";
      let legend = {
        data: data.data.series.map((d) => d.name),
        top: "bottom",
        type: "scroll",
      };
      let series = data.data.series.map((s) => ({
        name: s.name,
        type: "bar",
        data: s.series_data.map((sd) => sd.value),
      }));
      let tooltip = {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter(p) {
          let filterNonZeroValues = p.filter((d) => d.data !== 0);
          let htmlHeader = p.length ? `<div><h6>${p[0].name}</h6></div>` : "";
          let htmlContent = filterNonZeroValues
            .map(
              (d) =>
                `<div>${d.marker} ${
                  d.seriesName
                } - <b>${vfPrefix} ${d.value.toLocaleString()} ${vfPostFix}</b></div>`
            )
            .join(" ");
          return `${htmlHeader}${htmlContent}`;
        },
      };

      let categories = data.data.series[0].series_data.map((sd) => sd.category);
      let valueAxis: any = { 
        type: "value",
        axisPointer: {
          type: "line",
          show: true,
          triggerTooltip: false,
          label: {
            show: true,
          }
      }};
      let categoryAxis: any = { 
        type: "category", 
        data: categories, 
        axisPointer: {
          type: "shadow",
          show: true,
          label: {
            show: true
          }
        }};

      let gridOption = {
        left: width < 650 ? "20%" : "10%",
        right: "5%",
      }
      let visualOrientation = data.config?.direction ?? "horizontal";
      let visualAxesOrientation =
        visualOrientation === "horizontal"
          ? {
              grid: gridOption,
              xAxis: valueAxis,
              yAxis: categoryAxis,
            }
          : {
              grid: gridOption,
              xAxis: categoryAxis,
              yAxis: valueAxis,
            };
      let dataZoom =
        categories.length > 5
          ? [
              {
                type: "slider",
                height: 20,
                borderColor: "none",
                startValue: 0,
                endValue: 4,
                moveHandleSize: -20,
                backgroundColor: "none",
                fillerColor: "#F1EDED",
                moveHandleStyle: {
                  color: "grey",
                  handleSize: 5,
                },
                dataBackground: {
                  areaStyle: {
                    color: "none",
                    opacity: 0,
                  },
                  lineStyle: {
                    color: "none",
                    opacity: 0,
                  },
                },
                z: 2,
                handleIcon: "circle",
                handleSize: 25,
                moveHandleIcon: chartIcons.scrollbarMoveHandle,
              },
              {
                type: "slider",
                height: 5,
                z: 0,
                startValue: 0,
                endValue: 4,
                moveHandleSize: -20,
                backgroundColor: "#F1EDED",
                moveHandleStyle: {
                  color: "grey",
                  handleSize: 5,
                },
                dataBackground: {
                  areaStyle: {
                    color: "none",
                    opacity: 0,
                  },
                  lineStyle: {
                    color: "none",
                    opacity: 0,
                  },
                },
                handleIcon: "",
                handleSize: 0,
                brushSelect: false,
              },
            ]
          : [];

      return Object.assign(
        {},
        {
          legend,
          series,
          tooltip,
          dataZoom,
        },
        visualAxesOrientation
      );
    }
    return {};
  }, [data, width]);

  return (
    <BaseChart
      height={height}
      width={width}
      options={options}
      chartType="bar"
    />
  );
};

export default MultiSetBarChart;
