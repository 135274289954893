import styled from "styled-components";
import bsBannerBackground from "../../../../images/bs-ai-banner-bg.svg";
import bsAiResultBackground from "../../../../images/bsAiBackground.png";
import { useState } from "react";
import BSAiCommentaryView from "../bsAiCommentaryView";

const BSAiAdvisorBanner = () => {
  const [showAiAdvisor, setShowAiAdvisor] = useState(false);

  const handleInvokeAiAdvisor = () => {
    setShowAiAdvisor(true);
  };

  return (
    <Wrapper className={showAiAdvisor ? "bs-result-bg" : ""}>
      {!showAiAdvisor ? (
        <div className="section">
          <span className="banner-title">
            Want an AI powered analysis of your business summary?{" "}
            <span className="cta" onClick={handleInvokeAiAdvisor}>
              Click here
            </span>
          </span>
        </div>
      ) : (
        <BSAiCommentaryView />
      )}
    </Wrapper>
  );
};

export default BSAiAdvisorBanner;

const Wrapper = styled.div`
  background-image: url(${bsBannerBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 72px;
  margin: 15px 40px 15px;
  border: 0px solid #ffffff;
  border-radius: 10px;
  background-color: linear-gradient(261.95deg, #3FDA74 -0.65%, #3F99DA 98.54%);
  &.bs-result-bg {
    background-color: linear-gradient(261.95deg, #3FDA74 -0.65%, #3F99DA 98.54%);
    background-image: url(${bsAiResultBackground});
    background-repeat: no-repeat;
  }
  .section {
    transform: translate(0px, 50%);
  }
  .section,
  .loading-section {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex-direction: column;
    .banner-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      .cta {
        margin-left: 5px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .info {
      color: rgb(255 255 255 / 68%);
      font-size: 14px;
    }
  }
`;
