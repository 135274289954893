import { Fragment } from "react";
import styled from "styled-components";
import { numFormatter } from "../../helpers/utils";
import { TooltipHelper } from "../tooltipHelper";
import { renderIndicator } from "./utils";
import { TickerDivider } from "./divider";
import { useTickerContext } from "../../helpers/hooks/useTickerContext";

const TickerBanner = () => {
  const { tickerData } = useTickerContext();
  const showTickerBanner = tickerData && tickerData.length > 0;

  return (
    <Wrapper className={showTickerBanner ? "show-background" : ""}>
      {showTickerBanner
        ? tickerData.map((d, i) => (
            <Fragment key={i}>
              <div className="ticker">
                <span className="label">{d.name}</span>
                <TooltipHelper
                  tooltipText={`${d.value_formatter} ${parseInt(
                    d.value
                  ).toLocaleString()}`}
                  placement="bottom">
                  <span className="value">
                    {`${d.value_formatter ?? ""} ${numFormatter(
                      parseInt(d.value)
                    )}`}
                  </span>
                </TooltipHelper>
                {renderIndicator(d.indication)}
              </div>
              {i !== tickerData.length - 1 && <TickerDivider />}
            </Fragment>
          ))
        : null}
    </Wrapper>
  );
};
export default TickerBanner;
const Wrapper = styled.div`
  height: 35px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  .ticker {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 23.4px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .label {
      margin-right: 10px;
      opacity: 0.7;
      font-weight: 600;
    }
    .value {
      cursor: default;
      color: #0d8a6a;
      font-weight: 800;
    }
    .up {
      color: #0d8a6a;
    }
    .down {
      color: #cb2e2e;
    }
  }
  .dot {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      circle {
        fill: ${({ theme }) => theme.ticker.circle_fill};
      }
    }
  }
  &.show-background {
    background: ${({ theme }) => theme.ticker.background_color};
    border-bottom: ${({ theme }) => theme.ticker.border_bottom};
  }
`;
