import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company_details: null,
  shouldUseEpocForTimeRange: true,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany(state, action) {
      state.company_details = action.payload;
    },
  },
});

export const { setCompany } = companySlice.actions;
export const reducer = companySlice.reducer;
