import { useMemo } from "react";
import { configureUrlWithParams } from "../../../helpers/utils";
import useSWR from "swr";
import offThreadQueryResponse from "../../../helpers/middleware/offThreadQueryResponse";
import { RootState } from "../../../state/store";
import { useSelector } from "react-redux";

const useFetchExtendedAData = ({
  dashboard,
  dashboardWidget,
  widgetUrl,
  filters,
  additionalFilters,
  shouldFetch,
}) => {
  const { company } = useSelector((state: RootState) => state);
  const timezoneOffset = company?.company_details?.time_zone_offset;

  const endpoint = useMemo(
    () =>
      configureUrlWithParams(
        `/api/v1/dar/${dashboard}/${dashboardWidget}/${widgetUrl}/extended`,
        {
          ...filters,
          additionalFilters,
          shouldUseEpocForTimeRange: true,
          timezoneOffset,
        }
      ),
    [dashboard, dashboardWidget, widgetUrl, filters, additionalFilters]
  );

  const {
    data,
    isLoading,
    error,
    mutate: retry,
  } = useSWR(shouldFetch ? endpoint : null, {
    use: [offThreadQueryResponse],
    revalidateIfStale: false,
    revalidateOnMount: false,
  });

  return {
    data,
    isLoading,
    error,
    retry,
  };
};

export { useFetchExtendedAData };
