import Modal from "@restart/ui/Modal";
import { createContext, useContext } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import styled, { keyframes } from "styled-components";

const ModalContext = createContext({
  size: "md",
});

const CustomModal = ({ show = false, children, size = "md" }) => {
  return (
    <ModalContext.Provider value={{ size }}>
      <StyledModal
        show={show}
        renderBackdrop={renderBackdrop}
        aria-labelledby="modal-label"
        className={size}>
        <>{children}</>
      </StyledModal>
    </ModalContext.Provider>
  );
};

CustomModal.CloseButton = ({ onClick }) => {
  return (
    <StyledCloseButton className="close-modal-btn" onClick={onClick}>
      <IoIosCloseCircle fontSize="50px" className="close-icon" fill="#212121" />
    </StyledCloseButton>
  );
};

CustomModal.Content = ({ children }) => {
  const { size } = useContext(ModalContext);
  return <StyledModalContent $size={size}>{children}</StyledModalContent>;
};

export default CustomModal;

const renderBackdrop = (props) => <Backdrop {...props} />;

const fadeInTop = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const StyledModalContent = styled.div<{ $size: string }>`
  overflow-y: scroll;
  height: ${(props) =>
    props.$size == "lg" ? "85vh" : props.$size == "md" ? "50vh" : "auto"};
`;

const StyledCloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: -23px;
  top: -18px;
  border-radius: 20px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  > svg {
    fill: "#212121" !important;
  }
`;

const StyledModal = styled(Modal)`
  color: #000000;
  animation: ${fadeInTop} 0.2s ease-in;
  position: fixed;
  &.lg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    min-height: 90vh;
  }
  &.md {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }
  &.sm {
    padding: 2%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    height: 30%;
  }
  z-index: 1040;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 20px;
`;

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;
