import { useMemo } from "react";
import styled from "styled-components";
import { filterRowData } from "./utils";

const ComparisonResultTable = (props: any) => {
  const barChartValue = useMemo(() => {
    let rowData: any[] = [];
    let highestValue = 0;
    let name = ""
    let configData = props.data.widget_data?.config?.bar;
    if (configData.name) {
      name = `${configData?.name} ${
        configData?.value_formatter === "" ? "" : `(${configData?.value_formatter})`
      }`;
    }
    if (props.data.widget_data.data.series.length) {
      let seriesData = props.data.widget_data.data.series;
      rowData = filterRowData(seriesData, "bar_value");
      if (rowData) {
        highestValue = Math.max(...rowData);
      }
    }
    return { rowData, highestValue, name };
  }, [props.data]);

  const lineChartValue = useMemo(() => {
    let rowData: any[] = [];
    let highestValue = 0;
    let name = "";
    let configData = props.data.widget_data?.config?.line;
    if (configData.name) {
      name = `${configData?.name} ${
        configData?.value_formatter === "" ? "" : `(${configData?.value_formatter})`
      }`;
    }
    if (props.data.widget_data.data.series.length) {
      let seriesData = props.data.widget_data.data.series;
      rowData = filterRowData(seriesData, "line_value");
      if (rowData) {
        highestValue = Math.max(...rowData);
      }
    }
    return { rowData, highestValue, name };
  }, [props.data]);

  return (
    <SimpleTableWrapper>
      <table>
        <thead>
          <tr>
            <th />
            {props.data.widget_data.data.series.map((dataSeries) => {
              return <th>{dataSeries.category}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <>
            {barChartValue.name ? (
              <tr>
                <td className="bold">
                  {barChartValue.name}
                </td>
                {barChartValue.rowData.map((data) => {
                  return (
                    <>
                      <td
                        className={`${
                          barChartValue.highestValue === data ? "highest" : ""
                        }`}
                      >
                        {data.toLocaleString()}
                      </td>
                    </>
                  );
                })}
              </tr>
            ) : (
              false
            )}
            {lineChartValue.name ? (
              <tr>
                <td className="bold">
                  {lineChartValue.name}
                </td>
                {lineChartValue.rowData.map((data) => {
                  return (
                    <>
                      <td
                        className={`${
                          lineChartValue.highestValue === data ? "highest" : ""
                        }`}
                      >
                        {data.toLocaleString()}
                      </td>
                    </>
                  );
                })}
              </tr>
            ) : (
              false
            )}
          </>
        </tbody>
      </table>
    </SimpleTableWrapper>
  );
};

export default ComparisonResultTable;

const SimpleTableWrapper = styled.div`
  padding: 3.5rem;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.widget.background};
  table {
    width: 100%;
    border-collapse: collapse;
    > thead {
      th {
        font-style: normal;
        font-weight: bold;
        font-size: 21px;
        line-height: 27px;
      }
    }
    th,
    td {
      border-left: 1px solid #f4e3e3;
      border-right: 1px solid #f4e3e3;
      padding: 1.5rem 2.5rem;
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
      &.highest {
        color: #0d8a6a;
      }
    }
    tr {
      &:not(:last-child) {
        border-bottom: 1px solid #f4e3e3;
        border-top: 1px solid #f4e3e3;
      }
    }
    .bold {
      font-weight: bold;
    }
  }
`;
