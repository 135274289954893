import { numFormatter } from "../../../helpers/utils";
import styled from "styled-components";
import { useContext } from "react";
import { WidgetContext } from "../../widgetContext";
import { TooltipHelper } from "../../tooltipHelper";
import LastUpdatedFooter from "../../widgetLastUpdatedFooter";
import { format } from "date-fns";

const SmallNumberCountWidget = () => {
  const widget = useContext(WidgetContext);

  const handleWidgetOnClick = () => {
    if (widget.widgetOnClick) {
      widget.widgetOnClick();
    }
  };

  return (
    <Wrapper
      className={`${widget.widgetData.variant} ${
        widget.isTimelessWidget ? "outline" : ""
      }`}
      onClick={handleWidgetOnClick}
    >
      <div
        className="widget-content"
        data-toggle="tooltip"
        data-placement="top"
      >
        <div className="data-container">
          <TooltipHelper
            tooltipText={`${widget.widgetData.pre_text} ${parseInt(
              widget.widgetData.value
            ).toLocaleString()}`}
            placement="bottom"
          >
            <span
              className="value currency"
              data-currency={widget.widgetData.pre_text}
            >
              {numFormatter(widget.widgetData.value)}
            </span>
          </TooltipHelper>
          {widget.widgetData.additional_value ? (
            <TooltipHelper
              tooltipText={`${widget.widgetData.additional_pre_text} ${parseInt(
                widget.widgetData.additional_value
              ).toLocaleString()}`}
              placement="top"
            >
              <span>{`${widget.widgetData.additional_pre_text} ${numFormatter(
                widget.widgetData.additional_value
              )}`}</span>
            </TooltipHelper>
          ) : null}
        </div>
        <div className="widget-title">{widget.widgetData.title}</div>
      </div>
      {widget.isTimelessWidget ? (
        <LastUpdatedFooter
          date={format(widget.lastUpdatedTime, "do MMMM yyyy")}
          time={format(widget.lastUpdatedTime, "hh:mm aaa")}
        />
      ) : null}
    </Wrapper>
  );
};

export default SmallNumberCountWidget;

const Wrapper = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.widget.background};
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.05));
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 25px;
  &.danger {
    background-color: #cb2e2e;
    color: #fff;
    border-radius: 20px;
    > .widget-content {
      .value {
        color: #fff !important;
      }
    }
  }
  &.outlined, &.outline {
    border-radius: 20px;
    border: 10px solid #acd5ca;
    > .widget-content {
      color: #0d7459;
      .value {
        color: #0d7459 !important;
      }
    }
  }

  .tooltip-container {
    width: calc(100%);
    height: calc(100%);
    position: unset;
  }
  .data-container {
    text-align: center;
  }
  .widget-title {
    font-size: 26px;
    line-height: 2rem;
    font-size: 26px;
    text-align: center;
    font-weight: 500;
    opacity: 0.8;
  }
  .widget-content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    .pre-text {
      position: relative;
      top: 30px;
      display: block;
    }
    .value {
      display: block;
      text-align: center;
      font-size: 4rem;
      font-weight: 800;
      letter-spacing: -2px;
      position: relative;
      color: ${({ theme }) => theme.tooltip.text};
      &.currency:before {
        position: absolute;
        content: attr(data-currency);
        font-size: 20px;
        top: 7px;
        left: 0;
        letter-spacing: 0px;
        font-weight: 500;
        line-height: 1rem;
      }
    }
  }
`;
